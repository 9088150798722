import axios from "axios";
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import moment from "moment";
import Vue from "vue";
import App from "./App.vue";
import "./assets/css/style.css";
import MyComponents from "./components";
import config from "./config";
import router from "./router";
import store from "./store";
import { getData } from "./utils/storage";

axios.defaults.baseURL = config.apiBaseUrl;
// Install BootstrapVue
Vue.use(BootstrapVue);
Vue.use(MyComponents);

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.mixin({
  created: function () {
    //this.hello();
  },
  data() {
    return {
      apiBaseUrl: config.apiBaseUrl,
      isLogisticsOfficer:
        getData("userInfo") == null ? false : getData("userInfo").Role == 2,
      isFirstLogin:
        getData("userInfo") == null
          ? false
          : getData("userInfo").NotFirstTimeLogin == 0,
      formErrors: {},
      orderWarning:
        "You may proceed with this order, but vehicle delivery will not be completed until you pay your overdue invoices in Parts and vehicles Accounts.",
      orderNoteWarning:
        "Note that vehicle delivery will not be completed until you pay your overdue invoices in Parts and vehicles Accounts."
    };
  },
  methods: {
    hello: function () {
      console.log("hello from mixin!");
    },
    formatDateWithAdd(date) {
      return moment(date).format("DD-MMM-YYYY hh:mm a");
    },
    formatDate(date) {
      return moment(date).format("DD-MMM-YYYY hh:mm a");
    },
    formatDateOnly(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    amount: function (value) {
      const { format } = require("number-currency-format");
      value = format(value, {
        currency: "=N=",
        spacing: false,
        currencyPosition: "LEFT"
      });
      return value;
    }
  },
  filters: {
    amount: function (value) {
      const { format } = require("number-currency-format");
      value = format(value, {
        currency: "=N=",
        spacing: false,
        currencyPosition: "LEFT"
      });
      return value;
    },
    amountWithoutSymbol: function (value) {
      const { format } = require("number-currency-format");
      value = format(value, {
        currency: " ",
        spacing: false,
        currencyPosition: "LEFT"
      });
      return value;
    },
    formatDate(date) {
      return moment(date).format("DD-MMM-YYYY hh:mm a");
    },
    comma(arr) {
      return arr; /*
      if (!arr) {
        return "";
      }
      let str = "";
      arr.forEach(item => {
        str += item + ", ";
      });

      str = str.substring(0, str.length - 2);

      return str;*/
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
